import React from 'react'
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import { Container } from '../components/global'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import styled from 'styled-components'

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
deckDeckGoHighlightElement();

const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.background.light};
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  ${props => props.theme.font_size.large}
  font-weight: 600;
`

const LinkContainer = styled(Container)`
  background-color: ${props => props.theme.color.background.light};
  margin-top: 16px;
  margin-bottom: 16px;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${props => props.theme.color.background.white};
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 16px;

  & .image-wrapper {
    align-self: center;
  }

  a {
    text-decoration: underline;
  }

  .image-wrapper {
    width: 100%
  }
`

export default function BlogPost({ data }) {
  const post = data.markdownRemark

  return (
    <Layout>
      <BlogContainer>
        <SEO title={post.frontmatter.title} description={post.frontmatter.description} />
        <LinkContainer>
          <StyledLink to="/blog">Get Django Saas Blog</StyledLink>
        </LinkContainer>
        <StyledContainer stlye={{flex: 1}}>
          <h1>{post.frontmatter.title}</h1>
          <small>Published on: {post.frontmatter.date}</small>
          <Img className="image-wrapper" fluid={post.frontmatter.featuredImage.childImageSharp.fluid} alt={post.frontmatter.featuredImageAlt} />
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </StyledContainer>
      </BlogContainer>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        featuredImageAlt
        featuredImage {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            fixed(height: 500, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`